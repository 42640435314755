import * as LDClient from 'launchdarkly-js-client-sdk';
import { useCallback } from 'react';
import { useLocalStorage } from 'usehooks-ts';
import { FeatureFlag, FeatureFlagValue } from './feature-flags';

export type LDClientTypes = {
    context: LDClient.LDContext;
    envKey: string;
};

const FEATURE_FLAGS_LOCAL_STORAGE_KEY = 'featureFlags';

const dispatchFeatureFlagsUpdatedInLocalStorageEvent = () => {
    window.dispatchEvent(
        new StorageEvent('local-storage', {
            key: FEATURE_FLAGS_LOCAL_STORAGE_KEY,
        }),
    );
};

export const LaunchDarklyClientSide = async ({
    envKey,
    context,
}: LDClientTypes): Promise<LDClient.LDClient> => {
    const client = LDClient.initialize(envKey, context, {
        disableSyncEventPost: true,
        streaming: false,
        sendEvents: true,
        fetchGoals: false,
    });

    return client.waitUntilReady().then(() => {
        localStorage.setItem('featureFlags', JSON.stringify(client.allFlags()));

        client.on('change', (changeSet: LDClient.LDFlagChangeset) => {
            const flagSet: LDClient.LDFlagSet = {};
            for (const key in changeSet) {
                flagSet[key] = changeSet[key].current;
            }
            const previous = JSON.parse(
                localStorage.getItem('featureFlags') || '{}',
            );
            localStorage.setItem(
                FEATURE_FLAGS_LOCAL_STORAGE_KEY,
                JSON.stringify({ ...previous, ...flagSet }),
            );
            dispatchFeatureFlagsUpdatedInLocalStorageEvent();
        });

        dispatchFeatureFlagsUpdatedInLocalStorageEvent();
        return client;
    });
};

export function useFeatureFlag<T extends FeatureFlagValue = boolean>(
    flag: FeatureFlag,
): T {
    const [featureFlags] = useLocalStorage<Record<string, FeatureFlagValue>>(
        FEATURE_FLAGS_LOCAL_STORAGE_KEY,
        {},
    );

    const getFlagValueOrDefault = useCallback(() => {
        return featureFlags[flag.key] ?? flag.defaultValue;
    }, [featureFlags, flag]);

    return getFlagValueOrDefault() as T;
}
