import { buildPtusFrontendUrl } from '@repairer-frontend/ptus-frontend-url-builder';

export const BASE_URLS = {
    jobs: '/jobs',
};

export const PAGES = {
    jobs: 'jobs',
    jobdetail: 'jobdetail',
    reports: buildPtusFrontendUrl('Reporting/ReportBusinessPerformanceList'),
    feedback: buildPtusFrontendUrl('CompanyFeedback/FeedbackAssigned'),
    settings: buildPtusFrontendUrl('Profile/MyCompany/RepairerSettings'),
    activity: buildPtusFrontendUrl('AllActivity'),
};

export const PATHS = {
    jobs: `${BASE_URLS.jobs}/active-jobs`,
    jobdetail: `/job/:id`,
};
